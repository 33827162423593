import React, { useState, useEffect } from "react";
import { FaTelegram, FaFacebook, FaYoutube } from "react-icons/fa";
import { db, auth } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import "./NavBar.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../assets/languageChange/LanguageSwitcher";
import { useTheme } from "../../ThemeContext";
import ThemeSwitcher from "../../assets/ThemeSwitcher/ThemeSwitcher";

const NavBar = () => {
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const { theme, updateTheme } = useTheme(); // Get theme from the context

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departmentsCollectionRef = collection(db, "departments");
        const data = await getDocs(departmentsCollectionRef);
        const departmentsData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDepartments(departmentsData);
      } catch (err) {
        console.error("Error fetching departments:", err);
      }
    };

    fetchDepartments();
  }, []);

  const handleDepartmentClick = (departmentId) => {
    navigate(`/news/department/${departmentId}`);
  };

  return (
    <nav className={`nav-wrapper${theme}`}>
      <div className="nav-container">
        <a href="https://nakmo.net/amp/amp.html" className="amp-nav-link">
          {t("newsFeed")}
        </a>
        <div className={`news-links${theme}`}>
          {departments.map((department) => (
            <a
              key={department.id}
              href={`/news/department/${department.id}`}
              className={`news-link${theme}`}
              onClick={() => handleDepartmentClick(department.id)}
            >
              {t(`departments.${department.id}`)}
            </a>
          ))}
        </div>
        <div className={`news-social${theme}`}>
          <a
            href="https://t.me/nakmonet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram />
            <p>Telegram</p>
          </a>
          <a href="/https://www.facebook.com/nakmonet">
            <FaFacebook />
            <p>Facebook</p>
          </a>
          <a href="https://www.youtube.com/@NakMo_flow">
            <FaYoutube />
            <p>Youtube</p>
          </a>
        </div>
        <div className="news-language">
          <LanguageSwitcher />
        </div>
        <div className={`news-faq${theme}`}>
          <Link to="/contacts">{t("contacts")}</Link>
          <Link to="/contacts">ADS</Link>
          <Link to="/about">{t("about")}</Link>
        </div>
        {user && (
          <Link to={`/profile/${user.uid}`} className="profile-link">
            Profile
          </Link>
        )}
      </div>
      <ThemeSwitcher updateTheme={updateTheme} />
    </nav>
  );
};
export default NavBar;
