import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import BottomLoading from "../assets/loading/BottomLoading";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

const NewsList = React.lazy(() => import("./Main/NewsList/NewsList"));
const AdsBlock = React.lazy(() => import("./Main/ads/AdsBlock"));
const NewsPage = React.lazy(() => import("../pages/newsPage/NewsPage"));
const Contacts = React.lazy(() => import("../pages/contacts/Contacts"));
const Registration = React.lazy(() => import("../pages/login/Register"));
const Login = React.lazy(() => import("../pages/login/Login"));
const Profile = React.lazy(() => import("../pages/profile/Profile"));
const MyPosts = React.lazy(() => import("../pages/myPosts/MyPosts"));
const CreateArticle = React.lazy(() =>
  import("../pages/createArticle/CreateArticle")
);
const PageNotFound = React.lazy(() =>
  import("../assets/pageNotFound/PageNotFound")
);
const About = React.lazy(() => import("../pages/about/About"));
const Edit = React.lazy(() => import("../pages/edit/Edit"));
const SuperAdminPanel = React.lazy(() =>
  import("../pages/superAdmin/SuperAdmin")
);
const Sitemap = React.lazy(() => import("../pages/sitemap/Sitemap"));
const ArticleList = React.lazy(() =>
  import("../pages/articleList/ArticleList")
);

const AppRoutes = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const checkAdmin = async () => {
          try {
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("uid", "==", currentUser.uid));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const userDoc = querySnapshot.docs[0];
              const data = userDoc.data();
              console.log("Fetched user data:", data);
              setIsAdmin(data.isAdmin);
            } else {
              setIsAdmin(false);
            }
          } catch (error) {
            console.error("Error checking admin status:", error);
            setIsAdmin(false);
          }
        };
        checkAdmin();
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Suspense fallback={<BottomLoading />}>
      <Routes>
        <Route
          path="/"
          element={
            <div className="other_media">
              <div className="news_list_other_media">
                <NewsList />
              </div>
              <div className="ads_block_other_media">
                <AdsBlock />
              </div>
            </div>
          }
        />
        <Route path="/news/:articleId" element={<NewsPage />} />
        <Route
          path="/news/department/:departmentId"
          element={
            <div className="other_media">
              <div className="news_list_other_media">
                <NewsList />
              </div>
              <div className="ads_block_other_media">
                <AdsBlock />
              </div>
            </div>
          }
        />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="admin" element={<Login />} />
        <Route path="admin/reg" element={<Registration />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/articles/:userId" element={<MyPosts />} />
        <Route
          path="/profile/:userId/create-article"
          element={<CreateArticle />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/edit/:articleId" element={<Edit />} />
        <Route path="/super-adminJoriknoc" element={<SuperAdminPanel />} />
        <Route path="sitemap" element={<Sitemap />} />
        <Route path="list" element={<ArticleList />} />
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
