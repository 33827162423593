import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCW6XcKPtbqx66Q4iuJ-7nBukXVkVbSnR0",
  authDomain: "news-1662.firebaseapp.com",
  projectId: "news-1662",
  storageBucket: "news-1662.appspot.com",
  messagingSenderId: "535916930883",
  appId: "1:535916930883:web:aef99e975e073e1ccff1f6",
  measurementId: "G-RQ88EYTGZF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
