import React from "react";
import "./LanguageSwitcher.css";
import { useTranslation } from "react-i18next";
import gbFlag from "../img/flags/gb.svg";
import ruFlag from "../img/flags/ru.svg";
import hyFlag from "../img/flags/am.svg";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    // Update the lang attribute of the HTML document
    document.documentElement.lang = lng;
  };

  return (
    <div className="language-switcher">
      <button onClick={() => handleLanguageChange("en")} className="lg-button">
        <img src={gbFlag} alt="Great Britain Flag" />
      </button>
      <button onClick={() => handleLanguageChange("ru")} className="lg-button">
        <img src={ruFlag} alt="Russia Flag" />
      </button>
      <button onClick={() => handleLanguageChange("hy")} className="lg-button">
        <img src={hyFlag} alt="Armenia Flag" />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
