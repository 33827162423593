import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/header/Header";
import NavBar from "./components/navbar/NavBar";
import AppRoutes from "./components/AppRoutes";
import Footer from "./components/footer/Footer";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useLocation } from "react-router-dom";
import { useTheme } from "./ThemeContext";

function App() {
  const [showNavBar, setShowNavBar] = useState(false);
  const location = useLocation();
  const { theme } = useTheme();

  // Проверяем размер экрана при загрузке приложения
  useEffect(() => {
    setShowNavBar(window.innerWidth >= 830);
  }, []);

  const toggleNavBar = () => {
    setShowNavBar(!showNavBar);
  };

  useEffect(() => {
    const handleResize = () => {
      setShowNavBar(window.innerWidth >= 830);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <div className={`App${theme}`}>
        <Header toggleNavBar={toggleNavBar} />
        <div className="app-container">
          {showNavBar && <NavBar />}
          <div className="app-main">
            <AppRoutes />
          </div>
        </div>
        <Footer />
        <SpeedInsights />
      </div>
    </Provider>
  );
}

export default App;
