import React from "react";
import { CiMenuFries } from "react-icons/ci";
import "./Header.css";
import { useTheme } from "../../ThemeContext";

const Header = ({ toggleNavBar }) => {
  const { theme } = useTheme();

  return (
    <>
      <header className={`header${theme}`}>
        <a href="/">
          <h1 className={`heading${theme}`}>NakMo</h1>
        </a>
        <button
          onClick={toggleNavBar}
          className={`nav-btn${theme}`}
          aria-label="Menu"
        >
          <CiMenuFries />
        </button>
      </header>
    </>
  );
};

export default Header;
