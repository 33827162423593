import React from "react";
import "./Loading.css"; // Ссылка на ваш CSS-файл

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
