import React, { useState, useEffect } from "react";
import "./ThemeSwitcher.css";
import { MdLightMode, MdDarkMode } from "react-icons/md";

const ThemeSwitcher = ({ updateTheme }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    updateTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    document.body.classList.add(theme);

    return () => {
      document.body.classList.remove(theme);
    };
  }, [theme]);

  return (
    <button className="theme-switch-button" onClick={toggleTheme}>
      {theme === "light" ? <MdDarkMode /> : <MdLightMode />}
    </button>
  );
};

export default ThemeSwitcher;
