import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/android-chrome-512x512.png";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import { useTheme } from "../../ThemeContext";

const Footer = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <footer className={`footer${theme}`}>
      <div className={`footer-content${theme}`}>
        <nav className={`footer-nav${theme}`}>
          <Link to="/contacts">{t("contacts")}</Link>
          <Link to="/contacts">ADS</Link>
          <Link to="/about">{t("about")}</Link>
        </nav>
        <div className={`footer-info${theme}`}>
          <p>All rights reserved</p>
          <p>Since by CC invest Group® 2024</p>
        </div>
      </div>
      <div className={`footer-image${theme}`}>
        <a href="/">
          <img src={Logo} alt="Home Logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
