import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";

const initialState = {
  articles: [],
  dailyNews: [],
  loadedCount: 0,
  hasMore: true,
  isLoading: true,
  error: null,
  selectedArticle: null,
};

export const fetchArticles = createAsyncThunk(
  "articles/fetchArticles",
  async (departmentId = null, thunkAPI) => {
    try {
      const articlesCollectionRef = collection(db, "articles");
      let q = query(articlesCollectionRef, orderBy("createdAt", "desc"));

      if (departmentId) {
        q = query(q, where("departmentId", "==", departmentId));
      }

      const loadedCount = thunkAPI.getState().articles.loadedCount;
      q = query(q, limit(7 + loadedCount)); // Загружаем все статьи до текущего индекса

      const data = await getDocs(q);

      const articlesData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toISOString(), // Convert to ISO string
      }));

      return { articlesData };
    } catch (err) {
      console.error("Error fetching articles:", err);
      throw err;
    }
  }
);

export const fetchArticleById = createAsyncThunk(
  "articles/fetchArticleById",
  async (articleId, thunkAPI) => {
    try {
      const articleDoc = await getDoc(doc(db, "articles", articleId));

      if (articleDoc.exists()) {
        return {
          articleData: {
            id: articleDoc.id,
            ...articleDoc.data(),
            createdAt: articleDoc.data().createdAt.toDate().toISOString(), // Convert to ISO string
          },
        };
      } else {
        throw new Error("Article not found.");
      }
    } catch (err) {
      console.error("Error fetching article:", err);
      throw err;
    }
  }
);

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    // ... другие редьюсеры, если нужно
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articles = action.payload.articlesData;
        state.dailyNews = action.payload.articlesData;
        state.loadedCount = action.payload.articlesData.length;
        state.hasMore = action.payload.articlesData.length >= 5;
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchArticleById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArticleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedArticle = action.payload.articleData;
      })
      .addCase(fetchArticleById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default articlesSlice.reducer;
